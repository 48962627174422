import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimsState } from '@wam/shared';

const getClaimsFeatureState = createFeatureSelector<ClaimsState>('claims');

export const getClaims = createSelector(getClaimsFeatureState, (state) => state.claims);

export const getSelectedClaim = createSelector(
  getClaimsFeatureState,
  (state) => state.selectedClaim,
);

export const getPending = createSelector(getClaimsFeatureState, (state) => state.pending);

export const getLoaded = createSelector(getClaimsFeatureState, (state) => state.loaded);

export const getUpstartSharedClaims = createSelector(
  getClaimsFeatureState,
  (state) => state.upstartSharedClaims,
);
